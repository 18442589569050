import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import Image from "next/image"

import { Quote } from '../quote-grid/quote-grid'

import CSS from './meet-graphic.module.css'

export const MeetGraphic = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["-10%", "40%"])


    return (
        <section ref={ref} className={CSS.layout}>

            <div className={CSS.quote}>
                <div className={`${CSS.tab} bg-primary`} />
                <div className="p-lg">
                    <Quote>
                        <p className="fs-3 fw-300 lh-2 c-primary">A well-managed and organised event at a very good location. Very good speakers and networking opportunities.</p>
                        <p className="mt-xs c-secondary"><span className="fw-600">Dimitris Manikis</span><br/>President EMEA<br/>Wyndham Hotels & Resorts</p>
                    </Quote>
                </div>
                <div className={`${CSS.tab} bg-primary`} />
            </div>

            <div className={CSS.graphic}>
                <motion.div className={CSS.graphicWrapper} style={{ y }}>
                    <Image src={`/assets/theme/meet.png`} width={720} height={630} layout={`responsive`} />
                </motion.div>
            </div>

        </section>
    )
}



export const MeetGraphicBase = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["-30%", "0%"])

    return (
        <div ref={ref} className={CSS.graphicBase}>
            <motion.div className={CSS.graphicBaseWrapper} style={{ y }}>
                <Image src={`/assets/theme/meet-base.png`} width={720} height={500} layout={`responsive`} />
            </motion.div>
        </div>
    )
}