import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import CSS from './spinner.module.css'

export const Spinner = ({ children }) => {

    let ref = useRef(null)

    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    })    
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["0%", "100%"])

    return (
        <div ref={ref} className={CSS.container}>
            
            <div 
                className={CSS.spinner} 
                animate={{ rotate: 220 }}
            >
                { children }
            </div>


            {/* <motion.div style={{ height: 10, width: y, backgroundColor: `red` }}>
            </motion.div> */}
          

        </div>
    )
}