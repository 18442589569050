import { SpeakerCarousel } from '../../blocks/speaker-carousel/speaker-carousel'
import { SpeakerCard } from '../../components/speaker-card/speaker-card'
import { SpeakerCardCms } from '../../components/speaker-card-cms/speaker-card-cms'
import { ArrowButton } from '../../components/arrow-button/arrow-button'

import CSS from './selected-speakers.module.css'

export const SelectedSpeakers = ({ speakers }) => {
    return (
        <section className={CSS.layout}>

            <div className={CSS.heading}>
                <p className='fs-0 caps lh-1 c-primary'>IDEEA 2023 Advisory Board</p>
            </div>

            <div className={`${CSS.body} maxw-45`}>
                <p className='fs-6 fw-400'>Industry leaders from the Centra - East Europe (CEE) hospitality investment community will take the stage at IDEEA in Prague to bring us bold ideas and spark conversations helping the community find inspiration and lessons and do business, that drive the hospitality industry forward. Check out who will be taking the stage in September.</p>
            </div>

            <div className={CSS.slider}>
                <SpeakerCarousel>
                    {
                        speakers.map( speaker => (                        
                            <SpeakerCardCms
                                key={speaker.id}
                                url={`/speakers/profile/${speaker.slug}`}
                                avatar={speaker.avatar}
                                title={speaker.title}
                                positions={speaker.positions}
                            />
                        ))
                    }
                </SpeakerCarousel>
            </div>

            <div className={CSS.actions}>
                <ArrowButton href='/advisory-board' theme={{ arrow: `var(--primary)`, border: `var(--primary)`, label: `black`}} >
                    See entire Advisory Board
                </ArrowButton>
            </div>

        </section>
    )
}