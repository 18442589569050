import Image from 'next/image'

import CSS from './speaker-card-cms.module.css'

export const SpeakerCardCms = ({ url, avatar, title, positions }) => {

    return (
        <a href={url} className={CSS.layout}>
            
            <div className={CSS.photoContainer}>
                {
                    avatar && avatar[0].url ?
                        <Image className={CSS.photo} src={avatar[0].url} layout='fill' alt=""/>
                    :
                        <Image className={CSS.photo} src={`/assets/ui/avatar-fallback.png`} layout='fill' alt=""/>
                }
            </div>

            <div className='pt-xs'>
                <p className='fs-5 fw-600 c-dark'>{ title }</p>

                {
                    positions.length && 
                    <>
                        <p>{positions[0].jobTitle}</p>
                        <p className='fw-500'>{positions[0].organisation}</p>
                    </>
                }
            </div>

        </a>
    )
}