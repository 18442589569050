import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import Image from 'next/image'

import HubspotForm from '../../components/hubspot-form/hubspot-form'

import CSS from './stay-updated.module.css'

export const StayUpdated = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'end center']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["10%", "-30%"])


    return (
        <>
            <div className={`${CSS.topTab} bg-primary`} />

            <section className={`${CSS.layout} bg-primary`}>

                <div className={`${CSS.content} formatted`}>
                    <p className="fs-1 caps lh-1">Stay updated about&nbsp;IDEEA</p>
                    <p className="fs-5">Sign up and be among the first to receive event updates such as newly confirmed speakers, on-site activities, price discounts, market insights and reports, and much more! You can easily unsubscribe at any time.</p>
                </div>

                <div className={`${CSS.form} maxw-30`}>
                    <HubspotForm portalId={`14527187`} formId={`a9dc91e8-c966-4d7a-acb8-992d75f6ede5`} />
                </div>

                <div ref={ref} className={CSS.cube}>
                    <motion.div className={CSS.cubeWrapper} style={{ y }}>
                        <Image src={`/assets/theme/hif-cube.png`} width={350} height={380} layout={`responsive`} />
                    </motion.div>
                </div>

            </section>

            {/* <Graphic /> */}

        </>
    )
}


const Graphic = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["0%", "-60%"])

    return (
        <section ref={ref} className={CSS.graphicLayout}>
            <div className={`${CSS.bottomTab} bg-primary`} />

            <div className={CSS.graphic}>
                <motion.div className={CSS.graphicWrapper} style={{ y }}>
                    <Image src={`/assets/theme/trends-base.png`} width={800} height={500} layout={`responsive`} />
                </motion.div>
            </div>

        </section>
    )
}