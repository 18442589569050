import { ArrowButton } from '../../components/arrow-button/arrow-button'

import CSS from './featured-content.module.css'

export const FeaturedContent = ({ children }) => {
    return (
        <section className={`${CSS.layout} bg-accent`}>
            <div className={CSS.heading}>
                <p className="fs-0 caps lh-1 c-primary">Featured content</p>
                <ArrowButton href='/content-library' theme={{ arrow: `white`, border: `white`, label: `var(--primary)`}}>
                    View Content Library
                </ArrowButton>
            </div>

            <div className={CSS.content}>
                { children }
            </div>
        </section>
    )
}