import { useRef } from "react"
import { motion, useScroll, useTransform } from 'framer-motion'

import Image from "next/image"

import CSS from './topics.module.css'

export const Topics = () => {

    let ref = useRef(null)
    
    let  scroll  = useScroll({
        target: ref,
        offset: ['start end', 'center start']
    })
    let y = useTransform( scroll.scrollYProgress, [0, 1], ["40%", "0%"])

    const scrollPane = ( direction ) => {
        console.log('direction:', direction)
    }

    return (
        <>
            <section className={`${CSS.topTab} bg-secondary`} />

            <section className={`${CSS.layout} bg-secondary`}>
                
                <div className={CSS.heading}>
                    <p className="fs-0 lh-1 caps">IDEEA 2023 <br/>topics</p>
                </div>

                <div className={CSS.scrollPane}>

                    <div className={CSS.scrollPaneContent}>                        

                        <section>
                            <p className="fs-lg c-primary lh-1">1</p>
                            <p className="fs-4 fw-600">The Future of Hospitality</p>
                            <p className="mt-xxs">What are the trends and how are hospitality companies changing according to those future expectations? </p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">2</p>
                            <p className="fs-4 fw-600">Sustainable Development  </p>
                            <p className="mt-xxs">Looking at real-life examples and best practice methodologies and case studies from both within and outside the hospitality industry.  We will be focussing on new developments and retrofit, technology, sustainable design and construction.</p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">3</p>
                            <p className="fs-4 fw-600">Value in Brand Recognition and Community Support  </p>
                            <p className="mt-xxs">With new hotel brands coming onto the market all the time, we will explore the value of these brands and their products. How they can serve and strengthen the connection with the local market in making it more resilient through cooperation and inclusivity which increase revenues.  </p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">4</p>
                            <p className="fs-4 fw-600">New Operating Models & Spaces</p>
                            <p className="mt-xxs">Travelers&apos; expectation are changing every day and spaces and operating models are adapting accordingly. How new spaces look like, what do they offer, what kind of operating models investors and owners can choose from and what benefits they bring…and how easy are all of those properties able to change and accommodate some new trends that will come in the future.</p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">5</p>
                            <p className="fs-4 fw-600">Niche Markets and Locations - Spotlight on Central & Eastern Europe</p>
                            <p className="mt-xxs">CEE is a complex market that includes all types of hospitality products, we take a close look at the types of products available, their value, locations and futuredevelopment prospects. We work together to understand what this landscape looks like in terms of investors, developers, brands and travellers. </p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">6</p>
                            <p className="fs-4 fw-600">Money </p>
                            <p className="mt-xxs">A closer look at financing in challenging times. Covid and state support, the effect of inflation and raising energy prices, who is interested in supporting hospitality development through investment, what is available and what are the expectations?  </p>
                        </section>

                        <section>
                            <p className="fs-lg c-primary lh-1">7</p>
                            <p className="fs-4 fw-600">Opportunities through technology, VR and personalisation </p>
                            <p className="mt-xxs">We will look at new operating models and spaces in hospitality. How has technology changed this through VR and personalisation for example and what are customers now expecting for the future? </p>
                        </section>

                    </div>

                </div>

                <div ref={ref} className={CSS.graphic}>
                    <motion.div className={CSS.graphicWrapper} style={{ y }}>
                        <Image src={`/assets/theme/trends.png`} width={800} height={630} layout={`responsive`} />
                    </motion.div>
                </div>

                <section className={`${CSS.bottomTab} bg-white`} />
                
            </section>

        </>
    )
}