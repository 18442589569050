import Image from 'next/image'

// import Icon from './img/quotes.svg'
import CSS from './quote-grid.module.css'


export const QuoteGrid = ({ children }) => {
    return (
        <div className={CSS.gridLayout}>
            { children }
        </div>
    )
}


export const QuoteGridSpacer = () => <div className={CSS.spacer}></div>


export const QuoteImage = ({ src, width, height, span }) => {
    return (
        <div className={`${CSS.imageContainer} ${ span === 2 && CSS.double}`}>
            <Image src={src} width={width} height={height} alt="" />
        </div>

    )
}


export const Quote = ({ children, span }) => {

    return (
        <div className={`${CSS.quoteContainer} ${ span === 2 && CSS.double}`} >
            <div className={CSS.iconBox} style={{ borderColor: `var(--secondary)` }}>
                <Icon className={CSS.icon} fill={`var(--secondary)`}/>
            </div>
            { children }
        </div>
    )
}


const Icon = ({ fill }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 35 28" style={{ fillRule:`evenodd`, clipRule:`evenodd`, strokeLinejoin:`round`, strokeMiterlimit: 2 }}>
            <g transform="matrix(1,0,0,1,-911,-319)">
                <path id="quotes" d="M920.815,332.129C921.868,332.384 922.852,332.98 923.765,333.915C925.023,335.202 925.652,336.81 925.652,338.74C925.652,340.787 924.95,342.557 923.546,344.048C922.142,345.54 920.417,346.285 918.37,346.285C916.381,346.285 914.656,345.554 913.193,344.092C911.731,342.63 911,340.846 911,338.74C911,337.921 911.175,336.942 911.526,335.801C911.877,334.661 912.521,333.213 913.457,331.458L920.212,319L926.178,322.158L920.815,332.129ZM939.678,332.129C940.731,332.384 941.714,332.98 942.628,333.915C943.886,335.202 944.515,336.81 944.515,338.74C944.515,340.787 943.798,342.557 942.365,344.048C940.932,345.54 939.192,346.285 937.145,346.285C935.215,346.285 933.518,345.554 932.056,344.092C930.594,342.63 929.863,340.846 929.863,338.74C929.863,337.921 930.038,336.942 930.389,335.801C930.74,334.661 931.384,333.213 932.319,331.458L939.075,319L945.041,322.158L939.678,332.129Z" style={{ fill: fill ? fill : `var(--primary)`, fillRule:`nonzero` }}/>
            </g>
        </svg>

    )
}