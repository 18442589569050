import Image from 'next/image'

import { TileGrid, Tile, TileSpacer } from '../tile-grid/tile-grid'

import CSS from './montage.module.css'


export const MontageGrid = ({ children }) => {
    return (
        <div className={CSS.gridLayout}>
            { children }
        </div>
    )
}

export const MontageSpacer = ({ children, backgroundColor, span }) => {
    return (
        <div 
            className={`
                ${CSS.spacer} 
                ${ span === 2 && CSS.double}
                ${ span === 3 && CSS.triple}
            `} 
            style={{ backgroundColor: backgroundColor }}
        >
            { children }
        </div>
    )
}

export const MontageImage = ({ src, width, height, span }) => {
    return (
        <div 
            className={`
                ${CSS.imageContainer} 
                ${ span === 2 && CSS.double}
                ${ span === 3 && CSS.triple}
            `}
        >
            <Image src={src} width={width} height={height} layout={`responsive`} alt="" />
        </div>

    )
}

export const MontageBox = ({ children, span, backgroundColor, bottom }) => {
    return (
        <div 
            className={`${CSS.box} ${ span === 2 && CSS.double} ${bottom && CSS.bottom}`} 
            style={{ backgroundColor: backgroundColor }}
        >
            { children }
        </div>
    )
}


